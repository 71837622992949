import styled from "styled-components"

export const Heading = styled.div``
export const Kicker = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  color: #69d1f0;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 16px;
  padding-top: 40px;
  @media (max-width: 768px) {
    padding-top: 24px;
  }
`

export const HeroWrapper = styled.div`
  background-size: cover;
  background-position: center;
  padding: 105px 35px 0 35px;
  font-family: "Roboto";
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 140px;
  .hero-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .heading {
    max-width: 650px;
    font-weight: 900;
    font-size: 40px;
    line-height: 72px;
    text-align: center;
    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 40px;
      line-height: 60px;
    }
  }
  .description {
    max-width: 770px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    margin: 16px 0 0;
    max-width: 670px;
    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
export const VideoSectionWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 48px;
  max-width: 1170px;
  margin-top: -45px;
  @media (max-width: 992px) {
    margin-top: -72px;
  }
  @media (max-width: 768px) {
    margin-top: -92px;
  }
  @media (max-width: 1170px) {
    max-width: 992px;
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
  @media (max-width: 576px) {
    max-width: 330px;
  }
`
export const ThumbnailWrapper = styled.div`
  position: relative;
  .image-content {
    width: 100%;
    max-height: 600px;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      object-fit: cover;
    }
    :after {
      content: "";
      border-radius: 12px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.5) 49.48%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url(.jpg);
    }
    .emptyImg {
      width: 100%;
      max-height: 800px;
      min-height: 450px;
      background: #d4d9e2;
      @media (max-width: 992px) {
        width: 100%;
        min-width: auto;
        min-height: auto;
      }
    }
  }
  .playicon{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    span {
      display: inline-block;
      cursor: pointer;
      line-height: 72px;
      img{
        width:150px;
        height:150px;
      }
    }
  }
`

export const BreadCrumbFlex = styled.div`
padding: 24px 15px;
left: 50%;
z-index: 9999;
width: 100%;
max-width: 1170px;
display: flex;
flex-wrap: wrap;
align-items: baseline;
a {
  text-decoration: none;
}
img {
  padding: 0 10px;
  transform: scale(80%) translateY(2px);
}
span {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: color 200ms ease;
  &:hover {
    color: #dddddd;
  }
}
@media (max-width: 992px) {
  max-width: 770px;
}
@media (max-width: 768px) {
  max-width: 506px;
}
`

export const IconImg = styled.div`
  img {
    &:last-child {
      display: none;
    }
  }
`
